<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    orderId: '',
    iframeUrl: ''
  })

  // 获取发票
  const getOrderInvoice = () => {
    data.iframeUrl = `${process.env.VUE_APP_PAYMENT_API}services/orders/invoice/${data.orderId}`
    // api.pay.getOrdersInvoice(data.orderId).then((res) => {
    //   console.log(res, '发票信息')
    // })
  }

  onMounted(() => {
    data.orderId = useRoute().query.orderId || ''
    console.log(useRoute(), 'data.orderId')
    getOrderInvoice()
  })
</script>

<template>
  <div class="page-box">
    <iframe :src="data.iframeUrl" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<style lang="less" scoped>
  .page-box {
    width: 100%;
    height: 100vh;
  }
</style>
